import React, { Component } from 'react'
import styled from '@emotion/styled'

import Input from '../components/form/input'
import TextArea from '../components/form/textarea'
import Button from '../components/button'

const Form = styled.form`
  display: flex;
  flex-flow: row wrap;
  max-width: 420px;
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  // handleChange - adds input value to state
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  // validate = e => {
  //   e.preventDefault()
  //   // TODO: check fields validity
  //   // https://developer.mozilla.org/en-US/docs/Learn/HTML/Forms/Form_validation
  //   const inputs = Array.from(document.querySelectorAll('[data-input]'))
  //   let errMsg = ''
  //   for (let i = 0; i < inputs.length; i++) {
  //     if (inputs[i].value === '') {
  //       errMsg += inputs[i].name + ' is empty\n'
  //     }
  //   }
  //   alert(errMsg)
  // }
  // handleSubmit
  handleSubmit = e => {
    // submit form - use fetch
    e.preventDefault()
    const form = e.target

    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(function() {
        alert("Thanks! I'll be in touch!")
      })
      .catch(error => alert(error))
  }
  render() {
    return (
      <Form name="Contact" onSubmit={this.handleSubmit} data-netlify="true">
        <Input
          type="text"
          id="name"
          name="name"
          label="Your Name"
          required="true"
          onChange={this.handleChange}
        />
        <Input
          type="email"
          id="email"
          name="email"
          label="Email Address"
          required="true"
          onChange={this.handleChange}
        />
        <TextArea
          id={'yourMessage'}
          name={'your-message'}
          label={'Your Message'}
          cols={'48'}
          rows={'6'}
          onChange={this.handleChange}
        />
        <Button type={'submit'} icon={'paper-plane'}>
          Send it
        </Button>
        <input type="hidden" name="form-name" value="Say Hello" />
      </Form>
    )
  }
}
