import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageWrap from '../components/page-wrap'
import ContactForm from '../forms/contact-form'

const Contact = props => {
  return (
    <Layout>
      <SEO title="Contact" />
      <PageWrap>
        <h1>Contact</h1>
        <p>
          Looking for help on a project?
          <br />
          Tell me about it!
        </p>
        <ContactForm />
      </PageWrap>
    </Layout>
  )
}

export default Contact
